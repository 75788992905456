import classes from "./TermsAndConditions.module.css";
import icon from "../../assets/images/icon.png";

const TermsAndConditions = ({}) => {
  return (
    <div className={classes.card}>
      <div className={classes.alphaContent}>
        <div className={classes.message}>Foundry is in Alpha</div>
        <div className={classes.emailMessage}>
          Please reach out to us at{" "}
          <span className={classes.email}>hello@foundry.bz</span> if you have
          any feedback.
        </div>
      </div>
      <div className={classes.cardContent}>
        <a href="#">About</a>
        <span className={classes.dot}>•</span>
        <a href="#">Policy</a>
        <span className={classes.dot}>•</span>
        <a href="#">Terms</a>
        <span className={classes.dot}>•</span>
        <a href="#">Advertising</a>
        <span className={classes.dot}>•</span>
        <a href="#">Ads</a>
        <span className={classes.dot}>•</span>
        <a href="#">More</a>
        <span className={classes.dot}>•</span>
        {/* <img src={icon} alt="icon" className={classes.icon}></img>
        <span className={classes.foundry}>Foundry</span> */}
        Foundry Labs Ltd. © 2024
      </div>
    </div>
  );
};

export default TermsAndConditions;
