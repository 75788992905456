import React, { useState, useRef, useEffect, useCallback } from "react";
import imageCompression from "browser-image-compression";
import {
  addDoc,
  collection,
  serverTimestamp,
  updateDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./PostModal.module.css";
import { useUpload } from "../../contexts/UploadContext";

const PostModal = ({
  onClose,
  currentUser,
  onManualClose,
  initialFile,
  canceledUploads,
  initialPost,
  onEditComplete,
  selectedStartup,
}) => {
  // Add debug logging

  useEffect(() => {
    console.log("PostModal - Received selectedStartup:", selectedStartup);
  }, [selectedStartup]);
  const {
    state,
    updateUploadProgress,
    removeUploadingPost,
    registerCancelCallback,
  } = useUpload();

  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState(0);
  const carouselRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [postText, setPostText] = useState(initialPost?.text || "");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState(initialPost?.content?.type || null);
  const [filePreview, setFilePreview] = useState(
    initialPost?.content?.url || null
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const textAreaRef = useRef(null);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [isFileHovered, setIsFileHovered] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState(
    initialPost?.content?.fileName || null
  );
  const [isVideoHovered, setIsVideoHovered] = useState(false);

  const [videoThumbnail, setVideoThumbnail] = useState(
    initialPost?.content?.thumbnail || null
  );
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const isEditMode = !!initialPost;
  const [hasChanges, setHasChanges] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);

  let activeUploadTasks = {};

  window.cancelUpload = (postId) => {
    if (activeUploadTasks[postId]) {
      activeUploadTasks[postId].forEach((task) => task.cancel());
      delete activeUploadTasks[postId];
    }
  };

  const handleTouchStart = (e) => {
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      handleNextImage();
    } else if (isRightSwipe) {
      handlePrevImage();
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === multipleImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? multipleImages.length - 1 : prevIndex - 1
    );
  };

  const handleMouseDown = (e) => {
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    setIsDragging(true);
    setDragStart(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const dragDistance = dragStart - e.clientX;
    if (Math.abs(dragDistance) > 50) {
      if (dragDistance > 0) {
        handleNextImage();
      } else {
        handlePrevImage();
      }
      setIsDragging(false);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleImageClick = (e) => {
    // Prevent click event when interacting with close icon or dot navigation
    if (
      e.target.closest(`.${classes.imageCloseIcon}`) ||
      e.target.closest(`.${classes.dotNavigation}`)
    ) {
      return;
    }
    handleNextImage();
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (initialPost) {
      setPostText(initialPost.text || "");
      if (initialPost.content) {
        setFilePreview(
          Array.isArray(initialPost.content.url)
            ? initialPost.content.url
            : [initialPost.content.url]
        );
        setFileType(initialPost.content.type);
        if (initialPost.content.type === "video") {
          setFileName(removeFileExtension(initialPost.content.fileName));
        } else {
          setFileName(initialPost.content.fileName);
        }
        if (initialPost.content.type === "image") {
          setMultipleImages(
            Array.isArray(initialPost.content.url)
              ? initialPost.content.url
              : [initialPost.content.url]
          );
        }
      }
    }
  }, [initialPost]);

  useEffect(() => {
    if (initialPost) {
      console.log("Initializing PostModal with post:", initialPost);
      setPostText(initialPost.text || "");
      if (initialPost.content) {
        setFileType(initialPost.content.type);
        if (Array.isArray(initialPost.content.url)) {
          setFilePreview(initialPost.content.url);
          setMultipleImages(initialPost.content.url);
        } else {
          setFilePreview(initialPost.content.url);
        }
        setFileName(initialPost.content.fileName);
      }
    }
  }, [initialPost]);

  useEffect(() => {
    if (isEditMode) {
      setHasChanges(postText.trim() !== initialPost.text.trim());
    }
  }, [postText, initialPost, isEditMode]);

  const renderFilePreview = () => {
    if (isProcessing) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    if (!filePreview && !initialPost?.content) return null;

    return (
      <FilePreview
        fileType={fileType || initialPost?.content?.type}
        filePreview={filePreview || initialPost?.content?.url}
        fileName={fileName || initialPost?.content?.fileName}
        isUploading={isUploading}
        multipleImages={multipleImages}
        currentImageIndex={currentImageIndex}
        handleImageChange={handleImageChange}
        videoThumbnail={videoThumbnail || initialPost?.content?.thumbnail}
        onRemove={handleRemoveFile}
      />
    );
  };

  const renderInitialPostContent = () => {
    if (!initialPost || !initialPost.content) return null;

    switch (initialPost.content.type) {
      case "image":
        return (
          <img
            src={initialPost.content.url}
            alt="Post content"
            className={classes.imagePreview}
          />
        );
      case "video":
        return (
          <video
            src={initialPost.content.url}
            className={classes.videoPreview}
            controls
          />
        );
      case "pdf":
        return (
          <div className={classes.filePreview}>
            <svg
              className={classes.pdfIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="orangered"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.75"
                d="M19 10V4a1 1 0 0 0-1-1H9.914a1 1 0 0 0-.707.293L5.293 7.207A1 1 0 0 0 5 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2M10 3v4a1 1 0 0 1-1 1H5m5 6h9m0 0-2-2m2 2-2 2"
              />
            </svg>
            <span>{initialPost.content.fileName}</span>
          </div>
        );
      default:
        return null;
    }
  };

  const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const removeFileExtension = (fileName) => {
    if (typeof fileName !== "string") {
      console.warn("fileName is not a string:", fileName);
      return fileName; // Return the original value if it's not a string
    }
    return fileName.replace(/\.[^/.]+$/, "");
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Restore scroll position
      window.scrollTo(0, scrollY);
    };
  }, []);

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false);
    }
    setMouseDownOnBackdrop(false);
  };

  const getIconColor = (iconType) => {
    if (!file) return "gray";
    if (
      iconType === "image" &&
      (fileType === "image" || fileType === "video")
    ) {
      return "orangered";
    }
    if (iconType === "file" && fileType === "pdf") {
      return "orangered";
    }
    return "gray";
  };

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const scrollToImage = (index) => {
    if (carouselRef.current) {
      const scrollPosition = index * carouselRef.current.offsetWidth;
      carouselRef.current.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (
      initialPost &&
      initialPost.content &&
      initialPost.content.type === "video"
    ) {
      setFileName(removeFileExtension(initialPost.content.fileName));
    }
  }, [initialPost]);

  useEffect(() => {
    scrollToImage(currentImageIndex);
  }, [currentImageIndex]);

  const handleRemoveFile = () => {
    setFile(null);
    setFileType(null);
    setFilePreview(null);
    setFileName(null);
    setVideoThumbnail(null);
    setMultipleImages([]);
    resetFileInput();
  };

  const handleRemoveImage = (index) => {
    setMultipleImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setFile((prevFiles) =>
      Array.isArray(prevFiles) ? prevFiles.filter((_, i) => i !== index) : null
    );
    setFileName((prevNames) =>
      Array.isArray(prevNames) ? prevNames.filter((_, i) => i !== index) : null
    );

    if (multipleImages.length === 1) {
      // If it's the last image, clear all image-related states
      handleRemoveFile();
    } else if (index === currentImageIndex) {
      // If the removed image is the current one, adjust the currentImageIndex
      setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : 0));
    }

    resetFileInput();
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const FilePreview = ({
    fileType,
    filePreview,
    fileName,
    isUploading,
    multipleImages,
    currentImageIndex,
    handleImageChange,
    videoThumbnail,
    onRemove,
  }) => {
    if (isUploading) {
      return (
        <div className={classes.uploadingPreview}>
          <span className={`material-symbols-outlined ${classes.loadingIcon}`}>
            progress_activity
          </span>
        </div>
      );
    }

    switch (fileType) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel} onClick={handleImageClick}>
              {multipleImages.map((img, index) => (
                <div
                  key={index}
                  className={`${classes.imageWrapper} ${
                    index === currentImageIndex ? classes.activeImage : ""
                  }`}
                >
                  <img
                    src={img}
                    alt={`Preview ${index + 1}`}
                    className={classes.postContentImage}
                  />
                  {!isEditMode && (
                    <button
                      className={classes.imageCloseIcon}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImage(index);
                      }}
                      aria-label="Remove image"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </button>
                  )}
                </div>
              ))}
              {multipleImages.length > 1 && (
                <div className={classes.dotNavigation}>
                  {multipleImages.map((_, index) => (
                    <span
                      key={index}
                      className={`${classes.dot} ${
                        index === currentImageIndex ? classes.activeDot : ""
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleImageChange(index);
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes.pdfPreview}>
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M14 7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7Zm2 9.387 4.684 1.562A1 1 0 0 0 22 17V7a1 1 0 0 0-1.316-.949L16 7.613v8.774Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>{fileName}</div>
                <div className={classes.open}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  Video viewable once posted.
                </div>
              </div>
              {!isEditMode && (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="none"
                  viewBox="0 0 24 24"
                  onClick={onRemove}
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18 17.94 6M18 18 6.06 6"
                  />
                </svg>
              )}
            </div>
          </div>
        );
      case "pdf":
        return (
          <div className={classes.pdfPreview}>
            <div className={classes.pdfBox}>
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  fillRule="evenodd"
                  d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className={classes.fileContainer}>
              <div className={classes.fileAbout}>
                <div className={classes.fileName}>{fileName}</div>
                <div className={classes.open}>
                  <svg
                    className="w-6 h-6 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  PDF viewable once posted.
                </div>
              </div>
              {!isEditMode && (
                <svg
                  className={classes.closeIcon}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  fill="none"
                  viewBox="0 0 24 24"
                  onClick={onRemove}
                >
                  <path
                    stroke="gray"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18 17.94 6M18 18 6.06 6"
                  />
                </svg>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1, // Set to your desired max file size
      maxWidthOrHeight: 1920, // Set to your desired max width/height
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        "Compression complete. Original size:",
        file.size,
        "Compressed size:",
        compressedFile.size
      );
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image:", error);
      return file; // Return original file if compression fails
    }
  };

  const VideoIcon = ({ isHovered }) => (
    <svg
      className={classes.videoIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fill="gray"
          fillRule="evenodd"
          d="M9 7V2.221a2 2 0 0 0-.5.365L4.586 6.5a2 2 0 0 0-.365.5H9Zm2 0V2h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9h5a2 2 0 0 0 2-2Zm-2 4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H9Zm0 2h2v2H9v-2Zm7.965-.557a1 1 0 0 0-1.692-.72l-1.268 1.218a1 1 0 0 0-.308.721v.733a1 1 0 0 0 .37.776l1.267 1.032a1 1 0 0 0 1.631-.776v-2.984Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM9 12h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1Zm5.697 2.395v-.733l1.269-1.219v2.984l-1.268-1.032Z"
        />
      )}
    </svg>
  );

  const ImageIcon = ({ isHovered }) => (
    <svg
      className={classes.imageIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="gray"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <>
          <path
            fillRule="evenodd"
            d="M13 10a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H14a1 1 0 0 1-1-1Z"
            clipRule="evenodd"
          />
          <path
            fillRule="evenodd"
            d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-7Zm.394 9.553a1 1 0 0 0-1.817.062l-2.5 6A1 1 0 0 0 8 19h8a1 1 0 0 0 .894-1.447l-2-4A1 1 0 0 0 13.2 13.4l-.53.706-1.276-2.553ZM13 9.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z"
            clipRule="evenodd"
          />
        </>
      ) : (
        <path
          stroke="gray"
          strokeLinecap="round"
          fill="none"
          strokeLinejoin="round"
          strokeWidth="1.75"
          d="M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 18h8l-2-4-1.5 2-2-4L8 18Zm7-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z"
        />
      )}
    </svg>
  );

  const FileIcon = ({ isHovered }) => (
    <svg
      className={classes.fileIcon}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="gray"
      viewBox="0 0 24 24"
    >
      {isHovered ? (
        <path
          fillRule="evenodd"
          d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
          clipRule="evenodd"
        />
      ) : (
        <path
          stroke="gray"
          strokeLinejoin="round"
          strokeWidth="2"
          fill="none"
          d="M5 17v-5h1.5a1.5 1.5 0 1 1 0 3H5m12 2v-5h2m-2 3h2M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m6 4v5h1.375A1.627 1.627 0 0 0 14 15.375v-1.75A1.627 1.627 0 0 0 12.375 12H11Z"
        />
      )}
    </svg>
  );

  const handlePostTextChange = (e) => {
    if (e.target.value.length <= 3000) {
      setPostText(e.target.value);
    }
  };

  const handleFileUpload = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      setIsProcessing(true);
      setIsUploading(true);
      setFile(null);
      setFileType(null);
      setFilePreview(null);

      try {
        if (selectedFiles[0].type.startsWith("image/")) {
          if (selectedFiles.length > 10) {
            setError("You can upload a maximum of 10 images.");
            return;
          }

          const compressedImages = await Promise.all(
            selectedFiles.map(async (img) => await compressImage(img))
          );

          setFile(compressedImages);
          setFileType("image");
          setFileName(compressedImages.map((img) => img.name));

          const previews = await Promise.all(
            compressedImages.map((img) => {
              return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(img);
              });
            })
          );

          setMultipleImages(previews);
          setFilePreview(previews[0]);
        } else if (selectedFiles[0].type.startsWith("video/")) {
          let fileToUse = selectedFiles[0];
          setFile(fileToUse);
          setFileType("video");
          setFileName(removeFileExtension(fileToUse.name));

          const reader = new FileReader();
          reader.onloadend = () => {
            setFilePreview(reader.result);
          };
          reader.readAsDataURL(fileToUse);

          const thumbnailBlob = await generateVideoThumbnail(fileToUse);
          if (thumbnailBlob) {
            const thumbnailUrl = URL.createObjectURL(thumbnailBlob);
            setVideoThumbnail(thumbnailUrl);
          }
        } else if (selectedFiles[0].type === "application/pdf") {
          setFile(selectedFiles[0]);
          setFileType("pdf");
          // Remove .pdf extension from the file name
          const fileNameWithoutExtension = removeFileExtension(
            selectedFiles[0].name
          );
          setFileName(fileNameWithoutExtension);
          setFilePreview(URL.createObjectURL(selectedFiles[0]));
        } else {
          throw new Error("Unsupported file type");
        }
      } catch (error) {
        console.error("Error processing file:", error);
        setError("Failed to process the file. Please try again.");
        resetFileInput();
      } finally {
        setIsProcessing(false);
        setIsUploading(false);
      }
    }
  };

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.playsInline = true;
      video.muted = true;

      video.onloadedmetadata = () => {
        video.currentTime = 1; // Set to 1 second to avoid potential black frames at the start
      };

      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        const aspectRatio = video.videoWidth / video.videoHeight;
        let width = 320;
        let height = 320 / aspectRatio;

        // If height is greater than 240, set height to 240 and adjust width
        if (height > 240) {
          height = 240;
          width = 240 * aspectRatio;
        }

        canvas.width = width;
        canvas.height = height;
        canvas.getContext("2d").drawImage(video, 0, 0, width, height);
        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.7
        );
      };

      video.onerror = () => {
        console.error("Error loading video for thumbnail generation");
        resolve(null);
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleFileClick = (type) => {
    if (fileInputRef.current) {
      resetFileInput(); // Reset the file input before opening the file dialog
      if (type === "image") {
        fileInputRef.current.setAttribute("accept", "image/*");
        fileInputRef.current.setAttribute("multiple", "true");
      } else if (type === "pdf") {
        fileInputRef.current.setAttribute("accept", ".pdf");
        fileInputRef.current.removeAttribute("multiple");
      } else if (type === "video") {
        fileInputRef.current.setAttribute("accept", "video/*");
        fileInputRef.current.removeAttribute("multiple");
      }
      fileInputRef.current.click();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const trimmedPostText = postText.trim();

      if (isEditMode && initialPost) {
        console.log("Initial post data:", initialPost);

        // Ensure we have valid path information
        if (!initialPost._path) {
          console.error("Missing path information");
          throw new Error("Invalid post data structure");
        }

        const { _path } = initialPost;
        console.log("Path information:", _path);

        let postRef;
        if (_path.isStartup) {
          if (!_path.userId || !_path.startupId) {
            console.error("Invalid startup path:", _path);
            throw new Error("Missing startup path information");
          }

          // Construct path for startup post using owner's ID
          postRef = doc(
            firestore,
            `users/${_path.userId}/startups/${_path.startupId}/posts/${initialPost.id}`
          );

          console.log("Using startup post path:", postRef.path);
        } else {
          // Regular user post path
          postRef = doc(
            firestore,
            `users/${_path.userId}/posts/${initialPost.id}`
          );

          console.log("Using regular post path:", postRef.path);
        }

        const updateData = {
          text: trimmedPostText,
          isEdited: trimmedPostText !== initialPost.text.trim(),
          lastModified: serverTimestamp(),
        };

        console.log("Updating document:", {
          path: postRef.path,
          data: updateData,
        });

        await updateDoc(postRef, updateData);
        console.log("Update successful");
        onEditComplete(trimmedPostText, updateData.isEdited);
      } else {
        // Get the correct posts collection reference
        const postsRef = selectedStartup
          ? collection(
              firestore,
              "users",
              currentUser.uid,
              "startups",
              selectedStartup.id,
              "posts"
            )
          : collection(firestore, "users", currentUser.uid, "posts");

        // Get current user data
        const userDoc = await getDoc(doc(firestore, "users", currentUser.uid));
        const userData = userDoc.exists() ? userDoc.data() : {};

        // Prepare the initial post data
        const postData = {
          text: trimmedPostText,
          createdAt: serverTimestamp(),
          latestActivity: serverTimestamp(),
          user: selectedStartup
            ? {
                id: `startup_${selectedStartup.id}`,
                firstName: selectedStartup.startupName,
                lastName: "",
                profileImage: selectedStartup.startupImage,
                bio: selectedStartup.bio || "",
                link: selectedStartup.link || null,
                linkText: selectedStartup.linkText || null,
                connectionType: "You",
              }
            : {
                id: currentUser.uid,
                firstName: userData.firstName || "",
                lastName: userData.lastName || "",
                profileImage: userData.profileImage || null,
                bio: userData.bio || "",
                link: userData.link || null,
                linkText: userData.linkText || null,
                connectionType: "You",
              },
          // Initialize content as null or pending based on file presence
          content: file
            ? {
                type: "pending",
                fileName: Array.isArray(file)
                  ? file.map((f) => f.name)
                  : file.name,
              }
            : null,
          isEdited: false,
        };

        // Add startup-specific fields if posting as startup
        if (selectedStartup) {
          postData.isStartup = true;
          postData.startupId = selectedStartup.id;
          postData.startupOwnerId = currentUser.uid;
          postData.originalPosterId = currentUser.uid;
        }

        const newPostRef = await addDoc(postsRef, postData);
        console.log("Post created with ID:", newPostRef.id);

        if (file) {
          uploadFileAndUpdatePost(file, newPostRef.id);
          onClose(true, newPostRef.id, true, 0, false, canceledUploads);
        } else {
          onClose(true, newPostRef.id, false, 100, true, canceledUploads);
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", {
        error,
        initialPost,
        pathInfo: initialPost?._path,
      });

      let errorMessage = "Failed to update post. ";
      if (error.message.includes("path information")) {
        errorMessage += "Invalid post data structure.";
      } else {
        errorMessage += `Error: ${error.message}`;
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const uploadFileToStorage = useCallback(
    async (file, postId, index, progressCallback) => {
      const storage = getStorage();
      const fileName = file.name || `image_${index}.jpg`;

      // For startups, include the user ID in the path since permissions are based on user ownership
      const storagePath = selectedStartup
        ? `startupImages/${currentUser.uid}/${selectedStartup.id}/${postId}/${fileName}` // Include both userId and startupId
        : `postContent/${currentUser.uid}/${postId}/${fileName}`;

      console.log("Using storage path:", storagePath);

      const fileRef = ref(storage, storagePath);

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytesResumable(fileRef, file);

        registerCancelCallback(postId, () => {
          uploadTask.cancel();
          reject(new Error("Upload canceled"));
        });

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressCallback(progress);
          },
          (error) => {
            if (error.code === "storage/canceled") {
              console.log("Upload was canceled");
            } else {
              console.error("Upload error:", error);
            }
            reject(error);
          },
          async () => {
            if (!state.cancelledPosts[postId]) {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              resolve(downloadURL);
            } else {
              reject(new Error("Upload canceled"));
            }
          }
        );
      });
    },
    [
      currentUser.uid,
      registerCancelCallback,
      state.cancelledPosts,
      selectedStartup,
    ]
  );

  const uploadThumbnail = async (thumbnailBlob, postId) => {
    if (!thumbnailBlob) {
      console.error("No thumbnail blob to upload");
      return null;
    }

    const storage = getStorage();
    const thumbnailPath = selectedStartup
      ? `startupImages/${currentUser.uid}/${selectedStartup.id}/${postId}/thumbnail.jpg`
      : `postContent/${currentUser.uid}/${postId}/thumbnail.jpg`;

    const thumbnailRef = ref(storage, thumbnailPath);

    try {
      const uploadTask = await uploadBytesResumable(
        thumbnailRef,
        thumbnailBlob
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);
      console.log("Thumbnail uploaded successfully:", downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading thumbnail:", error);
      return null;
    }
  };

  const uploadFileAndUpdatePost = useCallback(
    async (file, postId) => {
      try {
        if (state.cancelledPosts[postId]) {
          throw new Error("Upload canceled");
        }

        let fileUrls = [];
        let thumbnailUrl = null;
        let fileNames = [];
        let finalFileType = fileType; // Preserve the file type

        // Handle file uploads
        if (Array.isArray(file)) {
          // Handle multiple files (images)
          fileUrls = await Promise.all(
            file.map((f, index) =>
              uploadFileToStorage(f, postId, index, (progress) => {
                if (state.cancelledPosts[postId]) {
                  throw new Error("Upload canceled");
                }
                updateUploadProgress(postId, progress, "uploading");
              })
            )
          );
          fileNames = file.map((f) => f.name);
        } else {
          // Handle single file (video or PDF)
          const url = await uploadFileToStorage(file, postId, 0, (progress) => {
            if (state.cancelledPosts[postId]) {
              throw new Error("Upload canceled");
            }
            updateUploadProgress(postId, progress, "uploading");
          });
          fileUrls = [url];
          fileNames = [file.name];

          // Handle video thumbnail if it's a video file
          if (file.type.startsWith("video/")) {
            const thumbnailBlob = await generateVideoThumbnail(file);
            if (thumbnailBlob) {
              thumbnailUrl = await uploadThumbnail(thumbnailBlob, postId);
            }
          }
        }

        if (state.cancelledPosts[postId]) {
          throw new Error("Upload canceled");
        }

        // Get correct document reference based on whether it's a startup post
        const postRef = selectedStartup
          ? doc(
              firestore,
              "users",
              currentUser.uid,
              "startups",
              selectedStartup.id,
              "posts",
              postId
            )
          : doc(firestore, "users", currentUser.uid, "posts", postId);

        // Prepare the content object based on file type
        const contentUpdate = {
          type: finalFileType,
          fileName: fileNames.length === 1 ? fileNames[0] : fileNames,
          url: fileUrls.length === 1 ? fileUrls[0] : fileUrls,
        };

        // Add thumbnail URL for videos
        if (thumbnailUrl) {
          contentUpdate.thumbnail = thumbnailUrl;
        }

        // Update the post document with the file content
        await updateDoc(postRef, {
          content: contentUpdate,
          latestActivity: serverTimestamp(),
        });

        console.log("Post updated successfully with content:", contentUpdate);
        onClose(true, postId, false, 100, true);
      } catch (error) {
        console.error("Error in uploadFileAndUpdatePost:", error);
        // If the error is due to cancellation, clean up storage files
        if (error.message === "Upload canceled") {
          // Implement cleanup logic here if needed
        }
        onClose(false, postId, false, 0, false);
      } finally {
        removeUploadingPost(postId);
      }
    },
    [
      state.cancelledPosts,
      updateUploadProgress,
      removeUploadingPost,
      onClose,
      uploadFileToStorage,
      generateVideoThumbnail,
      uploadThumbnail,
      fileType,
      currentUser.uid,
      selectedStartup,
    ]
  );

  useEffect(() => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [postText]);

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {isEditMode ? "Edit post" : "Add a post"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={isEditMode ? onClose : onManualClose}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <div className={classes.scrollableContent}>
          <form>
            <div className={classes.textAreaWrapper}>
              <textarea
                ref={textAreaRef}
                value={postText}
                onChange={handlePostTextChange}
                className={classes.textArea}
                placeholder="What's new?"
              />
            </div>
            {renderFilePreview()}
            {error && <p className={classes.error}>{error}</p>}
          </form>
        </div>
        {!isEditMode && (
          <div className={classes.fileUploadIcons}>
            <div className={classes.fileIcons}>
              <div
                onMouseEnter={() => setIsImageHovered(true)}
                onMouseLeave={() => setIsImageHovered(false)}
                onClick={() => handleFileClick("image")}
              >
                <ImageIcon isHovered={isImageHovered} />
              </div>
              <div
                onMouseEnter={() => setIsVideoHovered(true)}
                onMouseLeave={() => setIsVideoHovered(false)}
                onClick={() => handleFileClick("video")}
              >
                <VideoIcon isHovered={isVideoHovered} />
              </div>
              <div
                className={classes.icon}
                onMouseEnter={() => setIsFileHovered(true)}
                onMouseLeave={() => setIsFileHovered(false)}
                onClick={() => handleFileClick("pdf")}
              >
                <FileIcon isHovered={isFileHovered} />
              </div>
            </div>
            <div className={classes.charCount}>{postText.length}/3000</div>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileUpload}
          style={{ display: "none" }}
        />

        <div className={classes.cardFooter}>
          <button
            type="submit"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={
              loading ||
              (!postText.trim() && !file) ||
              (isEditMode && !hasChanges)
            }
            onClick={handleSubmit}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : isEditMode ? (
              "Save Changes"
            ) : (
              "Post"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
