import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import defaultImage from "../../../assets/icons/profileImage.jpg";
import startupImage from "../../../assets/images/startupImage.jpg";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "./Startup.module.css";
import { getTopThreeEmojis } from "../../../utils/emojiUtils";
import { firestore } from "../../../firebase";
import ConnectionsModal from "../../../components/Modals/ConnectionsModal";
import EditSingleStartupModal from "../../../components/Modals/EditSingleStartupModal";
import IntroModal from "../../../components/Modals/IntroModal";
import ActivityButtons from "../../../components/Buttons/ActivityButtons";
import FollowModal from "../../../components/Modals/FollowModal";
import TermsAndConditions from "../../../components/Cards/TermsAndConditions";
import {
  doc,
  getDoc,
  collection,
  writeBatch,
  increment,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";
import ReactionsModal from "../../../components/Modals/ReactionsModal";
import RepostsModal from "../../../components/Modals/RepostsModal";

function Startup() {
  const [stripeStatus, setStripeStatus] = useState(null);
  const [isSettingUpPayment, setIsSettingUpPayment] = useState(false);
  const { startupUrlSlug } = useParams();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [startupData, setStartupData] = useState({
    startupName: "",
    bio: "",
    city: "",
    country: "",
    link: "",
    linkText: "",
    startupImage: "",
    industry: "",
    role: "",
    intro: "",
    companyType: "",
  });
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [isRefreshingStripeSetup, setIsRefreshingStripeSetup] = useState(false);
  const [showStripeSuccess, setShowStripeSuccess] = useState(false);
  const [startupNotFound, setStartupNotFound] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [connectionsModalScrollPosition, setConnectionsModalScrollPosition] =
    useState(0);
  const [isConnectionsModalOpen, setIsConnectionsModalOpen] = useState(false);
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const [introModalScrollPosition, setIntroModalScrollPosition] = useState(0);
  const [showEditSingleStartupModal, setShowEditSingleStartupModal] =
    useState(false);
  const [isEditSingleStartupModalOpen, setIsEditSingleStartupModalOpen] =
    useState(false);
  const [
    editSingleStartupModalScrollPosition,
    setEditSingleStartupModalScrollPosition,
  ] = useState(0);
  const [showEditTeamModal, setShowEditTeamModal] = useState(false);
  const [isEditTeamModalOpen, setIsEditTeamModalOpen] = useState(false);
  const [editTeamModalScrollPosition, setEditTeamModalScrollPosition] =
    useState(0);

  const [loadingPosts, setLoadingPosts] = useState(false);
  const [loadingReactions, setLoadingReactions] = useState(false);
  const [loadingReposts, setLoadingReposts] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [followStatus, setFollowStatus] = useState("Follow");
  const [posts, setPosts] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [reposts, setReposts] = useState([]);
  const [commentedPosts, setCommentedPosts] = useState([]);
  const [showConnectionsModal, setShowConnectionsModal] = useState(false);

  const [displayedReactions, setDisplayedReactions] = useState(3);
  const [displayedReposts, setDisplayedReposts] = useState(3);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [isOwner, setIsOwner] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 648);
  const [isLoading, setIsLoading] = useState(true);
  const [activityType, setActivityType] = useState("Posts");
  const [showFullIntro, setShowFullIntro] = useState(false);
  const [displayedPosts, setDisplayedPosts] = useState(3);
  const [showReactionsModal, setShowReactionsModal] = useState(false);
  const [showRepostsModal, setShowRepostsModal] = useState(false);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [followersModalScrollPosition, setFollowersModalScrollPosition] =
    useState(0);
  const [isFollowersModalOpen, setIsFollowersModalOpen] = useState(false);

  const handleShowFollowersModal = () => {
    setFollowersModalScrollPosition(window.scrollY);
    setShowFollowersModal(true);
    setIsFollowersModalOpen(true);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isSuccess = params.get("success") === "true";
    const isRefresh = params.get("refresh") === "true";

    if (isSuccess) {
      setShowStripeSuccess(true);
      window.history.replaceState({}, "", window.location.pathname);
    } else if (isRefresh) {
      setIsRefreshingStripeSetup(true);
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (startupData.stripeAccountId) {
      checkStripeStatus();
    }
  }, [startupData.stripeAccountId]);

  const handleCloseFollowersModal = () => {
    setShowFollowersModal(false);
    setIsFollowersModalOpen(false);
    setTimeout(() => {
      window.scrollTo(0, followersModalScrollPosition);
    }, 0);
  };

  const [activityCounts, setActivityCounts] = useState({
    posts: 0,
    reactions: 0,
    reposts: 0,
    comments: 0,
  });

  const InvitePopup = ({ onClose, isModalOpen }) => (
    <div
      className={`${classes.repostPopup} ${
        isModalOpen ? classes.modalOpen : classes.modalClosed
      }`}
    >
      <div className={classes.repostPopupContent}>
        <svg
          className={classes.tickIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="orangered"
          viewBox="0 0 24 24"
        >
          <path
            fillRule="evenodd"
            d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
            clipRule="evenodd"
          />
        </svg>
        <p>Invite sent.</p>
      </div>
      <div onClick={onClose} className={classes.repostCloseButton}>
        <svg
          className={classes.closeIcon}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18 17.94 6M18 18 6.06 6"
          />
        </svg>
      </div>
    </div>
  );

  const toggleEditTeamModal = () => {
    if (!showEditTeamModal) {
      setEditTeamModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, editTeamModalScrollPosition);
      }, 0);
    }
    setShowEditTeamModal(!showEditTeamModal);
    setIsEditTeamModalOpen(!isEditTeamModalOpen);
  };

  const fetchTeamMembers = async () => {
    if (!startupData.id || !startupData.ownerId) return;

    try {
      // Path to team sub-collection within the startup document
      const teamRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/team`
      );

      // Get all active team members
      const teamQuery = query(teamRef, where("status", "==", "active"));
      const teamSnapshot = await getDocs(teamQuery);

      const teamMembersPromises = teamSnapshot.docs.map(async (teamDoc) => {
        // The document ID itself is the userUUID
        const userUUID = teamDoc.id;
        const teamMemberData = teamDoc.data();

        // Get the user's data from their user document
        const userRef = doc(firestore, "users", userUUID);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          return {
            id: teamDoc.id,
            userUUID: userUUID,
            role: teamMemberData.role || "",
            isCoFounder: teamMemberData.isCoFounder || false,
            acceptedAt: teamMemberData.acceptedAt,
            createdAt: teamMemberData.createdAt,
            status: teamMemberData.status,
            userData: {
              firstName: userData.firstName || "",
              lastName: userData.lastName || "",
              profileImage: userData.profileImage || "",
              city: userData.city || "",
              country: userData.country || "",
              urlSlug: userData.urlSlug || "",
            },
          };
        }
        return null;
      });

      const resolvedTeamMembers = (
        await Promise.all(teamMembersPromises)
      ).filter((member) => member !== null);
      setTeamMembers(resolvedTeamMembers);
    } catch (error) {
      console.error("Error fetching team members:", error);
    }
  };

  const NoContentCard = ({ onRefresh }) => (
    <div className={classes.noNotifications}>
      <div className={classes.bold}>Page not found</div>
      <div className={classes.text}>Please check your internet connection.</div>
      <div className={classes.homeButton} onClick={onRefresh}>
        <svg
          className="w-6 h-6 text-gray-800 dark:text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4"
          />
        </svg>
        Refresh
      </div>
    </div>
  );

  const toggleConnectionsModal = () => {
    if (!showConnectionsModal) {
      setConnectionsModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, connectionsModalScrollPosition);
      }, 0);
    }
    setShowConnectionsModal(!showConnectionsModal);
    setIsConnectionsModalOpen(!isConnectionsModalOpen);
  };

  const toggleEditSingleStartupModal = (isSave = false) => {
    if (!showEditSingleStartupModal) {
      // Store current scroll position when opening modal
      setEditSingleStartupModalScrollPosition(window.scrollY);
      // Add no-scroll class to body when opening modal
      document.body.classList.add(classes.bodyNoScroll);
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      // Remove no-scroll class when closing modal
      document.body.classList.remove(classes.bodyNoScroll);
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";
      // Restore scroll position after modal closes
      setTimeout(() => {
        window.scrollTo(0, editSingleStartupModalScrollPosition);
      }, 0);
    }
    setShowEditSingleStartupModal(!showEditSingleStartupModal);
    setIsEditSingleStartupModalOpen(!isEditSingleStartupModalOpen);
  };

  // Add this handler function
  const handleStartupUpdate = (updatedStartup) => {
    setStartupData((prevData) => ({
      ...prevData,
      ...updatedStartup,
    }));
  };

  const fetchStartupPosts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingPosts(true);
    try {
      const postsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/posts`
      );
      const postsSnapshot = await getDocs(
        query(postsRef, orderBy("createdAt", "desc"), limit(20))
      );

      const postsData = postsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt?.toDate() || new Date(),
          postPreview: {
            mediaType: data.content?.type || null,
            mediaUrl: data.content?.url || null,
            text: data.text,
            thumbnail: data.content?.thumbnail || null,
          },
          reactions: data.reactions || {},
          reposts: data.reposts || {},
          commentCount: data.commentCount || 0,
        };
      });

      setPosts(postsData);
    } catch (error) {
      console.error("Error fetching startup posts:", error);
    } finally {
      setLoadingPosts(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const fetchStartupReactions = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingReactions(true);
    try {
      // Get all reactions
      const reactionsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reactedPosts`
      );
      const reactionsQuery = query(
        reactionsRef,
        orderBy("reactedAt", "desc"),
        limit(20)
      );
      const reactionsSnapshot = await getDocs(reactionsQuery);

      const reactionsData = await Promise.all(
        reactionsSnapshot.docs.map(async (docSnapshot) => {
          const reactionData = docSnapshot.data();

          // Get reference to original post
          const originalPostRef = await getOriginalPostRef(
            reactionData.originalPosterId,
            reactionData.postId
          );

          if (!originalPostRef) {
            console.warn("Could not find post reference for:", reactionData);
            return null;
          }

          // Get the post data
          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) {
            console.warn("Post does not exist at:", originalPostRef.path);
            return null;
          }

          const originalPostData = originalPostSnap.data();

          // For startup posts, get the startup info
          let startupInfo = null;
          if (reactionData.originalPosterId.startsWith("startup_")) {
            const startupId = reactionData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...reactionData,
            originalPost: {
              id: reactionData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
              isStartupPost:
                reactionData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            reactedAt: reactionData.reactedAt.toDate(),
            emoji: reactionData.emoji,
          };
        })
      );

      const validReactions = reactionsData.filter(
        (reaction) => reaction !== null
      );
      console.log("All valid reactions:", validReactions);
      setReactions(validReactions);
    } catch (error) {
      console.error("Error fetching startup reactions:", error);
    } finally {
      setLoadingReactions(false);
    }
  }, [startupData.id, startupData.ownerId]);

  // Similar updates for comments and reposts
  const fetchStartupComments = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingComments(true);
    try {
      const commentsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/commentedPosts`
      );
      const commentsQuery = query(
        commentsRef,
        orderBy("lastCommentedAt", "desc"),
        limit(20)
      );
      const commentsSnapshot = await getDocs(commentsQuery);

      const commentsData = await Promise.all(
        commentsSnapshot.docs.map(async (docSnapshot) => {
          const commentData = docSnapshot.data();
          const originalPostRef = await getOriginalPostRef(
            commentData.originalPosterId,
            commentData.postId
          );

          if (!originalPostRef) return null;

          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) return null;

          const postData = originalPostSnap.data();

          // Get startup info if it's a startup post
          let startupInfo = null;
          if (commentData.originalPosterId.startsWith("startup_")) {
            const startupId = commentData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...commentData,
            originalPost: {
              id: commentData.postId,
              ...postData,
              postPreview: {
                mediaType: postData.content?.type || null,
                mediaUrl: postData.content?.url || null,
                text: postData.text || "",
                thumbnail: postData.content?.thumbnail || null,
              },
              isStartupPost:
                commentData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            lastCommentedAt: commentData.lastCommentedAt.toDate(),
          };
        })
      );

      const validComments = commentsData.filter((comment) => comment !== null);
      setCommentedPosts(validComments);
    } catch (error) {
      console.error("Error fetching startup comments:", error);
    } finally {
      setLoadingComments(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const fetchStartupReposts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    setLoadingReposts(true);
    try {
      const repostsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reposts`
      );
      const repostsQuery = query(
        repostsRef,
        orderBy("repostedAt", "desc"),
        limit(20)
      );
      const repostsSnapshot = await getDocs(repostsQuery);

      const repostsData = await Promise.all(
        repostsSnapshot.docs.map(async (docSnapshot) => {
          const repostData = docSnapshot.data();
          const originalPostRef = await getOriginalPostRef(
            repostData.originalPosterId,
            repostData.postId
          );

          if (!originalPostRef) return null;

          const originalPostSnap = await getDoc(originalPostRef);
          if (!originalPostSnap.exists()) return null;

          const originalPostData = originalPostSnap.data();

          // Get startup info if it's a startup post
          let startupInfo = null;
          if (repostData.originalPosterId.startsWith("startup_")) {
            const startupId = repostData.originalPosterId.replace(
              "startup_",
              ""
            );
            // Get startup info from the path of the post reference
            const pathParts = originalPostRef.path.split("/");
            const userId = pathParts[1]; // users/{userId}/startups/{startupId}/posts/{postId}
            const startupRef = doc(
              firestore,
              `users/${userId}/startups/${startupId}`
            );
            const startupDoc = await getDoc(startupRef);
            if (startupDoc.exists()) {
              startupInfo = startupDoc.data();
            }
          }

          return {
            id: docSnapshot.id,
            ...repostData,
            originalPost: {
              id: repostData.postId,
              ...originalPostData,
              postPreview: {
                mediaType: originalPostData.content?.type || null,
                mediaUrl: originalPostData.content?.url || null,
                text: originalPostData.text || "",
                thumbnail: originalPostData.content?.thumbnail || null,
              },
              isStartupPost: repostData.originalPosterId.startsWith("startup_"),
              startupInfo: startupInfo,
            },
            repostedAt: repostData.repostedAt.toDate(),
          };
        })
      );

      const validReposts = repostsData.filter((repost) => repost !== null);
      setReposts(validReposts);
    } catch (error) {
      console.error("Error fetching startup reposts:", error);
    } finally {
      setLoadingReposts(false);
    }
  }, [startupData.id, startupData.ownerId]);

  const getOriginalPostRef = async (originalPosterId, postId) => {
    try {
      // For startup posts (originalPosterId starts with "startup_")
      if (originalPosterId.startsWith("startup_")) {
        const startupId = originalPosterId.replace("startup_", "");

        // Find the startup owner by searching through all users
        const usersRef = collection(firestore, "users");
        const usersSnapshot = await getDocs(usersRef);

        for (const userDoc of usersSnapshot.docs) {
          const startupRef = doc(
            firestore,
            `users/${userDoc.id}/startups/${startupId}`
          );
          const startupSnap = await getDoc(startupRef);

          if (startupSnap.exists()) {
            // Found the startup, get its post
            const postRef = doc(
              firestore,
              `users/${userDoc.id}/startups/${startupId}/posts/${postId}`
            );
            const postSnap = await getDoc(postRef);

            if (postSnap.exists()) {
              return postRef;
            }
          }
        }
        console.log(
          `Could not find startup post. StartupId: ${startupId}, PostId: ${postId}`
        );
      } else {
        // For user posts (check both personal posts and startup posts)

        // First check user's startups
        const startupsRef = collection(
          firestore,
          `users/${originalPosterId}/startups`
        );
        const startupsSnapshot = await getDocs(startupsRef);

        // Check each startup's posts
        for (const startupDoc of startupsSnapshot.docs) {
          const postRef = doc(
            firestore,
            `users/${originalPosterId}/startups/${startupDoc.id}/posts/${postId}`
          );
          const postSnap = await getDoc(postRef);

          if (postSnap.exists()) {
            return postRef;
          }
        }

        // If not found in startups, check user's personal posts
        const userPostRef = doc(
          firestore,
          `users/${originalPosterId}/posts/${postId}`
        );
        const userPostSnap = await getDoc(userPostRef);

        if (userPostSnap.exists()) {
          return userPostRef;
        }

        console.log(
          `Could not find post for user ${originalPosterId} with postId ${postId}`
        );
      }
      return null;
    } catch (error) {
      console.error("Error in getOriginalPostRef:", error);
      return null;
    }
  };

  const fetchStartupActivityCounts = useCallback(async () => {
    if (!startupData.id || !startupData.ownerId) return;
    try {
      // Updated paths to include startups subcollection
      const postsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/posts`
      );
      const postsSnapshot = await getDocs(query(postsRef, limit(1)));
      const postsCount = postsSnapshot.size;

      const reactionsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reactedPosts`
      );
      const reactionsSnapshot = await getDocs(query(reactionsRef, limit(1)));
      const reactionsCount = reactionsSnapshot.size;

      const repostsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/reposts`
      );
      const repostsSnapshot = await getDocs(query(repostsRef, limit(1)));
      const repostsCount = repostsSnapshot.size;

      const commentsRef = collection(
        firestore,
        `users/${startupData.ownerId}/startups/${startupData.id}/commentedPosts`
      );
      const commentsSnapshot = await getDocs(query(commentsRef, limit(1)));
      const commentsCount = commentsSnapshot.size;

      setActivityCounts({
        posts: postsCount,
        reactions: reactionsCount,
        reposts: repostsCount,
        comments: commentsCount,
      });
    } catch (error) {
      console.error("Error fetching startup activity counts:", error);
    }
  }, [startupData.id, startupData.ownerId]);

  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      if (activityType === "Posts") {
        fetchStartupPosts();
      } else if (activityType === "Reactions") {
        fetchStartupReactions();
      } else if (activityType === "Comments") {
        fetchStartupComments();
      } else if (activityType === "Reposts") {
        fetchStartupReposts();
      }
    }
  }, [
    startupData.id,
    startupData.ownerId,
    activityType,
    fetchStartupPosts,
    fetchStartupReactions,
    fetchStartupComments,
    fetchStartupReposts,
  ]);

  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      fetchStartupActivityCounts();
    }
  }, [startupData.id, startupData.ownerId, fetchStartupActivityCounts]);

  const toggleIntroModal = () => {
    if (!showIntroModal) {
      setIntroModalScrollPosition(window.scrollY);
    } else {
      setTimeout(() => {
        window.scrollTo(0, introModalScrollPosition);
      }, 0);
    }
    setShowIntroModal(!showIntroModal);
    setIsIntroModalOpen(!isIntroModalOpen);
  };

  const formatTimestamp = (date) => {
    const now = new Date();
    const diff = now - date;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) return "now";
    if (minutes < 60) return `${minutes}m`;
    if (hours < 24) return `${hours}h`;
    if (days < 7) return `${days}d`;
    if (weeks < 4) return `${weeks}w`;
    if (months < 12) return `${months}mo`;
    return `${years}y`;
  };

  const truncateText = (text, lines) => {
    const words = text.split(" ");
    const truncated = words.slice(0, lines * 10).join(" ");
    return truncated.length < text.length ? `${truncated}...` : truncated;
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal(true);
    setIsReactionsModalOpen(true);
  };

  const handleRepostsClick = (postId, reposts) => {
    setRepostsModalScrollPosition(window.scrollY);
    setActivePostReposts({ postId, reposts });
    setShowRepostsModal(true);
    setIsRepostsModalOpen(true);
  };

  const handlePostClick = (postId, fromComments = false) => {
    let navigationPath;
    let state = {
      fromStartup: true,
      startupData: {
        startupName: startupData.startupName,
        startupImage: startupData.startupImage,
        startupId: startupData.id,
        startupOwnerId: startupData.ownerId,
        startupUrlSlug: startupData.startupUrlSlug,
      },
    };

    switch (activityType) {
      case "Reactions":
        const reaction = reactions.find((r) => r.originalPost.id === postId);
        if (reaction) {
          navigationPath = `/reaction/${postId}`;
          state.fromStartupReactions = true;
          state.reactionEmoji = reaction.emoji;
        }
        break;
      case "Comments":
        navigationPath = `/comment/${postId}`;
        state.fromStartupComments = true; // Add this
        break;
      case "Reposts":
        navigationPath = `/repost/${postId}`;
        break;
      default: // "Posts" or any other case
        navigationPath = `/post/${postId}`;
    }

    navigate(navigationPath, { state });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 648);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const checkOwnership = useCallback(
    async (startupData) => {
      if (!currentUser) {
        setIsOwner(false);
        return;
      }

      try {
        // Directly check the current user's startups subcollection
        const userStartupsRef = collection(
          firestore,
          `users/${currentUser.uid}/startups`
        );
        const startupsQuery = query(
          userStartupsRef,
          where("startupUrlSlug", "==", startupUrlSlug)
        );
        const startupsSnapshot = await getDocs(startupsQuery);

        setIsOwner(!startupsSnapshot.empty);
      } catch (error) {
        console.error("Error checking startup ownership:", error);
        setIsOwner(false);
      }
    },
    [currentUser, startupUrlSlug]
  );

  const fetchStartupData = async (urlSlug) => {
    try {
      setIsLoading(true);
      const usersRef = collection(firestore, "users");
      const usersSnapshot = await getDocs(usersRef);
      let startupFound = false;

      for (const userDoc of usersSnapshot.docs) {
        const startupsRef = collection(
          firestore,
          `users/${userDoc.id}/startups`
        );
        const startupsQuery = query(
          startupsRef,
          where("startupUrlSlug", "==", urlSlug)
        );
        const startupsSnapshot = await getDocs(startupsQuery);

        if (!startupsSnapshot.empty) {
          startupFound = true;
          const startupDoc = startupsSnapshot.docs[0];
          const data = startupDoc.data();

          // Get owner's user document
          const ownerDoc = await getDoc(doc(firestore, "users", userDoc.id));
          const ownerData = ownerDoc.data();

          setStartupData({
            id: startupDoc.id,
            ownerId: userDoc.id,
            startupName: data.startupName || "",
            bio: data.bio || "",
            city: data.city || "",
            country: data.country || "",
            countryISO2: data.countryISO2 || "",
            link: data.link || "",
            linkText: data.linkText || "",
            startupImage: data.startupImage || "",
            industry: data.industry || "",
            role: data.role || "",
            intro: data.intro || "",
            introHtml: data.introHtml || "",
            companyType: data.companyType || "",
            followingCount: data.followingCount || 0,
            monthFounded: data.monthFounded || "",
            yearFounded: data.yearFounded || "",
            startupUrlSlug: data.startupUrlSlug || "",
            // Add owner information
            ownerData: {
              id: userDoc.id,
              firstName: ownerData.firstName || "",
              lastName: ownerData.lastName || "",
              profileImage: ownerData.profileImage || "",
              city: ownerData.city || "",
              country: ownerData.country || "",
              urlSlug: ownerData.urlSlug || "",
            },
          });
          break;
        }
      }

      if (!startupFound) {
        setStartupNotFound(true);
      }
    } catch (error) {
      console.error("Error fetching startup data:", error);
      setStartupNotFound(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async (userId) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setCurrentUser((prevUser) => ({
          ...prevUser,
          ...userData,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    let unsubscribe;
    let isMounted = true;

    const initializeApp = async () => {
      try {
        // Set up auth listener
        unsubscribe = onAuthStateChanged(auth, async (user) => {
          if (!isMounted) return;

          if (user) {
            setCurrentUser(user);
            await fetchUserData(user.uid);
          } else {
            setCurrentUser(null);
            setIsOwner(false);
          }

          // Fetch startup data after auth state is determined
          if (startupUrlSlug) {
            await fetchStartupData(startupUrlSlug);
          }
        });
      } catch (error) {
        console.error("Error initializing app:", error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    initializeApp();

    // Cleanup function
    return () => {
      isMounted = false;
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [startupUrlSlug]); // Remove checkOwnership from dependencies

  useEffect(() => {
    const checkStartupOwnership = async () => {
      if (currentUser && startupUrlSlug) {
        await checkOwnership();
      }
    };

    checkStartupOwnership();
  }, [currentUser, startupUrlSlug, checkOwnership]);

  const renderDescriptionCard = () => {
    // If there's no intro and user is not the owner, don't render the card at all
    if (!startupData.introHtml && !isOwner) {
      return null;
    }

    return (
      <div className={classes.card}>
        <div
          className={`${classes.cardTitle} ${
            !startupData.introHtml ? classes.emptyCardTitle : ""
          }`}
        >
          Intro
          {isOwner && (
            <div onClick={toggleIntroModal}>
              <svg
                className={classes.closeIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                />
              </svg>
            </div>
          )}
        </div>
        <div className={classes.introText}>
          {startupData.introHtml ? (
            <pre>
              {renderIntroText().text}
              {renderIntroText().showMore && (
                <span
                  className={classes.moreButton}
                  onClick={() => setShowFullIntro(true)}
                >
                  ...more
                </span>
              )}
            </pre>
          ) : (
            isOwner && (
              <span className={classes.emptyIntroText}>
                Tell the world about your startup. What problem are you solving?
                What's your mission? Share your story and inspire others.
              </span>
            )
          )}
        </div>
      </div>
    );
  };

  const renderActivityCard = () => {
    const hasActivity =
      activityCounts.posts > 0 ||
      activityCounts.reactions > 0 ||
      activityCounts.reposts > 0 ||
      activityCounts.comments > 0;

    // Remove the condition that prevents rendering for owners
    // Only hide for non-owners when there's no activity
    if (!isOwner && !hasActivity) return null;

    return (
      <div className={classes.activityCard}>
        <div
          className={`${classes.activityCardTitle} ${
            !hasActivity ? classes.emptyCardTitle : ""
          }`}
        >
          Activity
        </div>
        <ActivityButtons
          selectedActivity={activityType}
          onSelectionChange={handleActivityChange}
          activityCounts={activityCounts}
          showAllButtons={true}
        />
        {/* Render different content based on activity type */}
        {activityType === "Posts" && renderPosts()}
        {activityType === "Reactions" && renderReactions()}
        {activityType === "Comments" && renderComments()}
        {activityType === "Reposts" && renderReposts()}
      </div>
    );
  };

  const renderEmptyPosts = () => (
    <div>
      <p className={classes.defaultTextBold}>You haven't posted yet</p>
      <p className={classes.defaultText}>
        Posts you create will be displayed here.
      </p>
      <div className={classes.noPostBorder}></div>
      <div className={classes.postHeader}>
        <span className={classes.postAuthor}>You</span>
        <span className={classes.postMessage}> posted this </span>
        <span className={classes.dot}>•</span>
        <span>now</span>
      </div>
      <div className={classes.noPost}>
        <div className={classes.postContent}>
          <div className={classes.defaultImage}>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="gray"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className={classes.noTextPreview}>Post</p>
        </div>
      </div>
      <div className={classes.noPostInteractions}>
        <div>100 reactions</div>
        <div className={classes.noRightInteractions}>
          <div>50 comments</div>
          <span className={classes.dot}>•</span>
          <div>25 reposts</div>
        </div>
      </div>
    </div>
  );

  const renderPosts = () => {
    if (loadingPosts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {posts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't posted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you create will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>

                  <span className={classes.postMessage}> posted this </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <div className={classes.defaultImage}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 reactions</div>
                  <div className={classes.noRightInteractions}>
                    <div>50 comments</div>
                    <span className={classes.dot}>•</span>
                    <div>25 reposts</div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No posts available</div>
            )
          ) : (
            <>
              {posts.slice(0, displayedPosts).map((post, index) => (
                <React.Fragment key={post.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {isOwner ? "You" : startupData.startupName}
                      </span>
                      <span className={classes.postMessage}> posted this </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(post.createdAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !post.postPreview.mediaType && post.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handlePostClick(post.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {post.postPreview?.mediaType &&
                          renderFilePreview(post.postPreview)}
                        {post.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(post.postPreview.text, 3)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {post.reactions &&
                      Object.keys(post.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={post.reactions}
                          onClick={() =>
                            handleReactionsClick(post.id, post.reactions)
                          }
                        />
                      )}
                    <div className={classes.rightInteractions}>
                      <PostInteractionCount
                        postId={post.id}
                        reposts={post.reposts}
                        commentCount={post.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() => handlePostClick(post.id)}
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {posts.length > 3 && displayedPosts < posts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() =>
              navigate(`/all-posts/startup/${startupData.id}`, {
                state: {
                  startupView: true,
                  startupId: startupData.id,
                  ownerId: startupData.ownerId,
                },
              })
            }
          >
            Show all posts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const renderReactions = () => {
    if (loadingReactions) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reactions.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't reacted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you react to will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>
                  <span className={classes.postMessage}>
                    {" "}
                    reacted <span className={classes.textEmoji}>🐘</span> to
                    this{" "}
                  </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <div className={classes.defaultImage}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 reactions</div>
                  <div className={classes.noRightInteractions}>
                    <div>50 comments</div>
                    <span className={classes.dot}>•</span>
                    <div>25 reposts</div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No reactions available</div>
            )
          ) : (
            <>
              {reactions.slice(0, displayedReactions).map((reaction, index) => {
                const isStartupPost =
                  reaction.originalPosterId.startsWith("startup_");

                return (
                  <React.Fragment key={reaction.id}>
                    {index !== 0 && <div className={classes.postBorder}></div>}
                    <div>
                      <div className={classes.postHeader}>
                        <span className={classes.postAuthor}>
                          {isOwner ? "You" : startupData.startupName}
                        </span>
                        <span className={classes.postMessage}>
                          {" "}
                          reacted{" "}
                          <span className={classes.textEmoji}>
                            {reaction.emoji}
                          </span>{" "}
                          to{" "}
                          {isStartupPost ? (
                            <span>
                              <span className={classes.startupPostIndicator}>
                                startup{" "}
                              </span>
                              post
                            </span>
                          ) : (
                            "this"
                          )}
                        </span>
                        <span className={classes.dot}>•</span>
                        <span>{formatTimestamp(reaction.reactedAt)}</span>
                      </div>
                      <div
                        className={`${classes.post} ${
                          !reaction.originalPost?.postPreview?.mediaType &&
                          reaction.originalPost?.postPreview?.text
                            ? classes.textOnlyPost
                            : ""
                        }`}
                        onClick={() =>
                          handleReactionClick(reaction.id, reaction.emoji)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className={classes.postContent}>
                          {reaction.originalPost?.postPreview?.mediaType &&
                            renderFilePreview(
                              reaction.originalPost.postPreview
                            )}
                          {reaction.originalPost?.postPreview?.text && (
                            <p className={classes.textPreview}>
                              {truncateText(
                                reaction.originalPost.postPreview.text,
                                3
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postInteractions}>
                      {reaction.originalPost?.reactions &&
                        Object.keys(reaction.originalPost.reactions).length >
                          0 && (
                          <ReactionsSummary
                            reactions={reaction.originalPost.reactions}
                            onClick={() =>
                              handleReactionsClick(
                                reaction.originalPost.id,
                                reaction.originalPost.reactions
                              )
                            }
                          />
                        )}
                      <div className={classes.rightInteractions}>
                        <PostInteractionCount
                          postId={reaction.originalPost?.id}
                          reposts={reaction.originalPost?.reposts}
                          commentCount={reaction.originalPost?.commentCount}
                          onClick={handleRepostsClick}
                          onCommentClick={() =>
                            handlePostClick(reaction.originalPost?.id)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
        {reactions.length > 3 && displayedReactions < reactions.length && (
          <div
            className={classes.showAllPosts}
            onClick={() =>
              navigate(`/all-reactions/startup/${startupData.id}`, {
                state: {
                  fromStartupReactions: true,
                  startupData: {
                    startupName: startupData.startupName,
                    startupImage: startupData.startupImage,
                    startupId: startupData.id,
                    startupOwnerId: startupData.ownerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                  },
                },
              })
            }
          >
            Show all reactions
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const renderReposts = () => {
    if (loadingReposts) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {reposts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't reposted yet
                </p>
                <p className={classes.defaultText}>
                  Posts you repost will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>

                  <span className={classes.postMessage}> reposted this </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <div className={classes.defaultImage}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 reactions</div>
                  <div className={classes.noRightInteractions}>
                    <div>50 comments</div>
                    <span className={classes.dot}>•</span>
                    <div>25 reposts</div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No reposts available</div>
            )
          ) : (
            <>
              {reposts.slice(0, displayedReposts).map((repost, index) => (
                <React.Fragment key={repost.id}>
                  {index !== 0 && <div className={classes.postBorder}></div>}
                  <div>
                    <div className={classes.postHeader}>
                      <span className={classes.postAuthor}>
                        {isOwner ? "You" : startupData.startupName}
                      </span>
                      <span className={classes.postMessage}>
                        {" "}
                        reposted this{" "}
                      </span>
                      <span className={classes.dot}>•</span>
                      <span>{formatTimestamp(repost.repostedAt)}</span>
                    </div>
                    <div
                      className={`${classes.post} ${
                        !repost.originalPost.postPreview.mediaType &&
                        repost.originalPost.postPreview.text
                          ? classes.textOnlyPost
                          : ""
                      }`}
                      onClick={() => handleRepostClick(repost.id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div className={classes.postContent}>
                        {repost.originalPost?.postPreview?.mediaType &&
                          renderFilePreview(repost.originalPost.postPreview)}
                        {repost.originalPost?.postPreview?.text && (
                          <p className={classes.textPreview}>
                            {truncateText(
                              repost.originalPost.postPreview.text,
                              3
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.postInteractions}>
                    {repost.originalPost?.reactions &&
                      Object.keys(repost.originalPost.reactions).length > 0 && (
                        <ReactionsSummary
                          reactions={repost.originalPost.reactions}
                          onClick={() =>
                            handleReactionsClick(
                              repost.originalPost.id,
                              repost.originalPost.reactions
                            )
                          }
                        />
                      )}
                    <div className={classes.rightInteractions}>
                      <PostInteractionCount
                        postId={repost.originalPost?.id}
                        reposts={repost.originalPost?.reposts}
                        commentCount={repost.originalPost?.commentCount}
                        onClick={handleRepostsClick}
                        onCommentClick={() =>
                          handlePostClick(repost.originalPost?.id)
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {reposts.length > 3 && displayedReposts < reposts.length && (
          <div
            className={classes.showAllPosts}
            onClick={() =>
              navigate(`/all-reposts/startup/${startupData.id}`, {
                state: {
                  fromStartupReactions: true,
                  startupData: {
                    startupName: startupData.startupName,
                    startupImage: startupData.startupImage,
                    startupId: startupData.id,
                    startupOwnerId: startupData.ownerId,
                    startupUrlSlug: startupData.startupUrlSlug,
                  },
                },
              })
            }
          >
            Show all reposts
            <svg
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 12H5m14 0-4 4m4-4-4-4"
              />
            </svg>
          </div>
        )}
      </>
    );
  };

  const renderComments = () => {
    if (loadingComments) {
      return (
        <div className={classes.loadingIndicator}>
          <span className={`${classes.loadingIcon} material-symbols-outlined`}>
            progress_activity
          </span>
        </div>
      );
    }

    return (
      <>
        <div className={classes.posts}>
          {commentedPosts.length === 0 ? (
            isOwner ? (
              <div>
                <p className={classes.defaultTextBold}>
                  You haven't commented yet
                </p>
                <p className={classes.defaultText}>
                  Posts you comment on will be displayed here.
                </p>
                <div className={classes.noPostBorder}></div>
                <div className={classes.postHeader}>
                  <span className={classes.postAuthor}>You</span>

                  <span className={classes.postMessage}>
                    {" "}
                    commented on this{" "}
                  </span>
                  <span className={classes.dot}>•</span>
                  <span>now</span>
                </div>
                <div className={classes.noPost}>
                  <div className={classes.postContent}>
                    <div className={classes.defaultImage}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="gray"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11.5c.07 0 .14-.007.207-.021.095.014.193.021.293.021h2a2 2 0 0 0 2-2V7a1 1 0 0 0-1-1h-1a1 1 0 1 0 0 2v11h-2V5a2 2 0 0 0-2-2H5Zm7 4a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h.5a1 1 0 1 1 0 2H13a1 1 0 0 1-1-1Zm-6 4a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1ZM7 6a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7Zm1 3V8h1v1H8Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className={classes.noTextPreview}>Post</p>
                  </div>
                </div>
                <div className={classes.noPostInteractions}>
                  <div>100 reactions</div>
                  <div className={classes.noRightInteractions}>
                    <div>50 comments</div>
                    <span className={classes.dot}>•</span>
                    <div>25 reposts</div>
                  </div>
                </div>
              </div>
            ) : (
              <div>No comments available</div>
            )
          ) : (
            <>
              {commentedPosts
                .slice(0, displayedComments)
                .map((comment, index) => {
                  const isStartupPost = comment.originalPost.isStartupPost;

                  return (
                    <React.Fragment key={comment.id}>
                      {index !== 0 && (
                        <div className={classes.postBorder}></div>
                      )}
                      <div>
                        <div className={classes.postHeader}>
                          <span className={classes.postAuthor}>
                            {isOwner ? "You" : startupData.startupName}
                          </span>
                          <span className={classes.postMessage}>
                            {" "}
                            commented on this
                          </span>
                          <span className={classes.dot}>•</span>
                          <span>
                            {formatTimestamp(comment.lastCommentedAt)}
                          </span>
                        </div>
                        <div
                          className={`${classes.post} ${
                            !comment.originalPost.postPreview.mediaType &&
                            comment.originalPost.postPreview.text
                              ? classes.textOnlyPost
                              : ""
                          }`}
                          onClick={() =>
                            handlePostClick(comment.originalPost.id, true)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <div className={classes.postContent}>
                            {comment.originalPost?.postPreview?.mediaType &&
                              renderFilePreview(
                                comment.originalPost.postPreview
                              )}
                            {comment.originalPost?.postPreview?.text && (
                              <p className={classes.textPreview}>
                                {truncateText(
                                  comment.originalPost.postPreview.text,
                                  3
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.postInteractions}>
                        {comment.originalPost?.reactions &&
                          Object.keys(comment.originalPost.reactions).length >
                            0 && (
                            <ReactionsSummary
                              reactions={comment.originalPost.reactions}
                              onClick={() =>
                                handleReactionsClick(
                                  comment.originalPost.id,
                                  comment.originalPost.reactions
                                )
                              }
                            />
                          )}
                        <div className={classes.rightInteractions}>
                          <PostInteractionCount
                            postId={comment.originalPost?.id}
                            reposts={comment.originalPost?.reposts}
                            commentCount={comment.originalPost?.commentCount}
                            onClick={handleRepostsClick}
                            onCommentClick={() =>
                              handlePostClick(comment.originalPost?.id)
                            }
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </>
          )}
        </div>
        {commentedPosts.length > 3 &&
          displayedComments < commentedPosts.length && (
            <div
              className={classes.showAllPosts}
              onClick={() =>
                navigate(`/all-comments/startup/${startupData.id}`, {
                  state: {
                    fromStartupComments: true,
                    startupData: {
                      startupName: startupData.startupName,
                      startupImage: startupData.startupImage,
                      startupId: startupData.id,
                      startupOwnerId: startupData.ownerId,
                      startupUrlSlug: startupData.startupUrlSlug,
                    },
                  },
                })
              }
            >
              Show all comments
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 12H5m14 0-4 4m4-4-4-4"
                />
              </svg>
            </div>
          )}
      </>
    );
  };

  const handleActivityChange = (selectedActivity) => {
    setActivityType(selectedActivity);
  };

  const handleLinkClick = (e) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
  };

  const renderIntroText = () => {
    const introText = startupData.introHtml || "No description available.";
    const maxLength = 250;

    if (introText.length <= maxLength || showFullIntro) {
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: introText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: false,
      };
    } else {
      const truncatedText = introText.slice(0, maxLength).trim();
      return {
        text: (
          <span
            dangerouslySetInnerHTML={{ __html: truncatedText }}
            onClick={handleLinkClick}
          />
        ),
        showMore: true,
      };
    }
  };

  const handleReactionClick = (reactionId, emoji) => {
    navigate(`/reaction/${reactionId}`, {
      state: {
        fromStartupReactions: true,
        startupData: {
          startupName: startupData.startupName,
          startupImage: startupData.startupImage,
          startupId: startupData.id,
          startupOwnerId: startupData.ownerId,
          startupUrlSlug: startupData.startupUrlSlug,
        },
        reactionEmoji: emoji,
      },
    });
  };

  const handleRepostClick = (repostId) => {
    navigate(`/repost/${repostId}`, {
      state: {
        fromStartupRepost: true, // Changed from fromStartup to fromStartupRepost
        startupData: {
          startupName: startupData.startupName,
          startupImage: startupData.startupImage,
          startupId: startupData.id,
          startupOwnerId: startupData.ownerId,
          startupUrlSlug: startupData.startupUrlSlug,
        },
      },
    });
  };

  const ReactionsSummary = ({ reactions, onClick }) => {
    const reactionCount = Object.keys(reactions).length;
    const topEmojis = getTopThreeEmojis(reactions);

    return (
      <div className={classes.reactionsSummary} onClick={onClick}>
        <div className={classes.reactionStack}>
          {topEmojis.reverse().map((emoji, index) => (
            <div
              key={index}
              className={classes.reactionEmoji}
              style={{ zIndex: topEmojis.length - index }}
            >
              {emoji}
            </div>
          ))}
        </div>
        <span className={classes.reactionCount}>{reactionCount}</span>
      </div>
    );
  };

  const PostInteractionCount = ({
    postId,
    reposts,
    commentCount,
    onClick,
    onCommentClick,
  }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0 && commentCount === 0) return null;

    return (
      <div className={classes.postInteractions}>
        {commentCount > 0 && (
          <span
            className={classes.commentText}
            onClick={() => onCommentClick(postId)}
          >
            {commentCount} comment{commentCount !== 1 ? "s" : ""}
          </span>
        )}
        {repostCount > 0 && commentCount > 0 && (
          <span className={classes.dot}>•</span>
        )}
        {repostCount > 0 && (
          <span
            className={classes.repost}
            onClick={() => onClick(postId, reposts)}
          >
            {repostCount} repost{repostCount !== 1 ? "s" : ""}
          </span>
        )}
      </div>
    );
  };

  const renderFilePreview = (postPreview) => {
    if (postPreview.mediaType === "image") {
      return (
        <div className={classes.mediaPreview}>
          <img src={postPreview.mediaUrl} alt="Post preview" />
          {Array.isArray(postPreview.mediaUrl) &&
            postPreview.mediaUrl.length > 1 && (
              <div className={classes.multipleImagesIndicator}>
                +{postPreview.mediaUrl.length - 1}
              </div>
            )}
        </div>
      );
    }

    if (postPreview.mediaType === "video") {
      const thumbnailUrl = postPreview.thumbnail || postPreview.mediaUrl;
      return (
        <div className={classes.mediaPreview}>
          <img src={thumbnailUrl} alt="Video preview" />
          <div className={classes.playButton}></div>
        </div>
      );
    }

    if (postPreview.mediaType === "pdf" || postPreview.fileName) {
      return (
        <div className={classes.filePreview}>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      );
    }

    return null;
  };

  // Add these new functions to handle follow functionality
  const checkFollowStatus = useCallback(async () => {
    if (!currentUser || !startupData.id) return;

    try {
      const followRef = doc(
        firestore,
        `users/${currentUser.uid}/startupsFollowing/${startupData.id}`
      );
      const followDoc = await getDoc(followRef);

      if (followDoc.exists()) {
        setFollowStatus("Following");
      } else {
        setFollowStatus("Follow");
      }
    } catch (error) {
      console.error("Error checking follow status:", error);
      setFollowStatus("Follow");
    }
  }, [currentUser, startupData.id]);

  const handleFollow = async () => {
    if (!currentUser || !startupData.id || !startupData.ownerId) return;

    const batch = writeBatch(firestore);

    const followRef = doc(
      firestore,
      `users/${currentUser.uid}/startupsFollowing/${startupData.id}`
    );

    const startupRef = doc(
      firestore,
      `users/${startupData.ownerId}/startups/${startupData.id}`
    );

    const userRef = doc(firestore, `users/${currentUser.uid}`);

    const followerRef = doc(
      firestore,
      `users/${startupData.ownerId}/startups/${startupData.id}/followers/${currentUser.uid}`
    );

    try {
      if (followStatus === "Follow") {
        batch.set(followRef, {
          ownerId: startupData.ownerId,
          followedAt: new Date(),
        });

        batch.set(followerRef, {
          followedAt: new Date(),
        });

        batch.update(startupRef, {
          followingCount: increment(1),
        });

        batch.update(userRef, {
          followingCount: increment(1),
        });

        const notificationRef = doc(
          collection(firestore, `users/${startupData.ownerId}/notifications`)
        );
        batch.set(notificationRef, {
          type: "startup_follow",
          from: currentUser.uid,
          startupId: startupData.id,
          startupName: startupData.startupName,
          startupUrlSlug: startupData.startupUrlSlug,
          startupOwnerId: startupData.ownerId,
          createdAt: new Date(),
          isNew: true,
        });

        await batch.commit();

        setFollowStatus("Following");
        setStartupData((prev) => ({
          ...prev,
          followingCount: (prev.followingCount || 0) + 1,
        }));
      } else {
        batch.delete(followRef);
        batch.delete(followerRef);

        batch.update(startupRef, {
          followingCount: increment(-1),
        });

        batch.update(userRef, {
          followingCount: increment(-1),
        });

        const notificationsRef = collection(
          firestore,
          `users/${startupData.ownerId}/notifications`
        );
        const q = query(
          notificationsRef,
          where("type", "==", "startup_follow"),
          where("from", "==", currentUser.uid),
          where("startupId", "==", startupData.id)
        );

        const notificationsSnapshot = await getDocs(q);
        notificationsSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });

        await batch.commit();

        setFollowStatus("Follow");
        setStartupData((prev) => ({
          ...prev,
          followingCount: Math.max((prev.followingCount || 0) - 1, 0),
        }));
      }
    } catch (error) {
      console.error("Error handling follow:", error);
    }
  };

  useEffect(() => {
    if (startupData.id && startupData.ownerId) {
      fetchTeamMembers();
    }
  }, [startupData.id, startupData.ownerId]);

  // Add this useEffect to check follow status when component mounts
  useEffect(() => {
    if (currentUser && startupData.id) {
      checkFollowStatus();
    }
  }, [currentUser, startupData.id, checkFollowStatus]);

  if (isLoading) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <div className={classes.loadingIndicator}>
              <span
                className={`${classes.loadingIcon} material-symbols-outlined`}
              >
                progress_activity
              </span>
            </div>
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  if (startupNotFound) {
    return (
      <div className={classes.page}>
        {isMobileView ? (
          <MobileNavbar currentUser={currentUser} />
        ) : (
          <MainNavbar currentUser={currentUser} />
        )}
        <div className={classes.content}>
          <div className={classes.centreCards}>
            <NoContentCard onRefresh={() => window.location.reload()} />
          </div>
          <div className={classes.rightCards}>
            <TermsAndConditions />
          </div>
        </div>
      </div>
    );
  }

  const handleSetupStripeAccount = async () => {
    if (!currentUser || !startupData.id) return;
    setIsSettingUpPayment(true);

    try {
      const functions = getFunctions();

      // If there's an existing account, use resume flow
      if (
        startupData.stripeAccountId &&
        startupData.stripeAccountStatus === "restricted"
      ) {
        const createResumeLink = httpsCallable(
          functions,
          "createResumeAccountLink"
        );
        const result = await createResumeLink({
          accountId: startupData.stripeAccountId,
          startupUrlSlug: startupData.startupUrlSlug,
          origin: window.location.origin,
        });

        if (result.data.success) {
          window.location.href = result.data.url;
        } else {
          throw new Error("Failed to create resume link");
        }
      } else {
        // Initial setup flow
        const createConnectAccount = httpsCallable(
          functions,
          "createConnectAccount"
        );
        const result = await createConnectAccount({
          startupId: startupData.id,
        });

        if (result.data.success) {
          const createAccountLink = httpsCallable(
            functions,
            "createAccountLink"
          );
          const linkResult = await createAccountLink({
            accountId: result.data.accountId,
            startupUrlSlug: startupData.startupUrlSlug,
            origin: window.location.origin,
          });

          if (linkResult.data.success) {
            window.location.href = linkResult.data.url;
          } else {
            throw new Error("Failed to create account link");
          }

          setStartupData((prev) => ({
            ...prev,
            stripeAccountId: result.data.accountId,
            stripeAccountStatus: "pending",
          }));
        } else {
          throw new Error(
            result.data.error || "Failed to create Stripe account"
          );
        }
      }
    } catch (error) {
      console.error("Error setting up payment account:", error);
      alert(`Failed to set up payment account: ${error.message}`);
    } finally {
      setIsSettingUpPayment(false);
    }
  };

  const checkStripeStatus = async () => {
    try {
      const functions = getFunctions();
      const checkStatus = httpsCallable(functions, "checkStripeAccountStatus");
      const result = await checkStatus({ startupId: startupData.id });

      // Update local state with the detailed status
      setStripeStatus(result.data);

      // Update startup data with the latest Stripe status
      setStartupData((prev) => ({
        ...prev,
        stripeAccountStatus: result.data.status,
        stripeAccountId: result.data.accountId,
      }));
    } catch (error) {
      console.error("Error checking status:", error);
    }
  };

  // Modify the payment setup button/section
  const renderStripeStatus = () => {
    if (!startupData.stripeAccountId) {
      return (
        <button
          className={classes.headlineButton}
          onClick={handleSetupStripeAccount}
        >
          Set up payment
        </button>
      );
    }

    if (startupData.stripeAccountStatus === "restricted") {
      return (
        <button
          className={classes.headlineButton}
          onClick={handleSetupStripeAccount}
        >
          Continue setup
        </button>
      );
    }

    if (stripeStatus?.detailsSubmitted) {
      return (
        <div className={classes.stripeStatus}>
          <div className={classes.stripeStatusHeader}>
            <svg className={classes.checkIcon} /* Add check icon SVG */ />
            <span>Payment account active</span>
          </div>
          {stripeStatus.accountLink && (
            <a
              href={stripeStatus.accountLink}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.dashboardLink}
            >
              View Stripe Dashboard
            </a>
          )}
        </div>
      );
    }

    return null;
  };

  // Add resume setup handler
  const handleResumeStripeSetup = async () => {
    try {
      const functions = getFunctions();
      const createResumeLink = httpsCallable(
        functions,
        "createResumeAccountLink"
      );
      const result = await createResumeLink({
        accountId: startupData.stripeAccountId,
        startupUrlSlug: startupData.startupUrlSlug,
        origin: window.location.origin,
      });

      if (result.data.success) {
        window.location.href = result.data.url;
      }
    } catch (error) {
      console.error("Error resuming setup:", error);
      alert("Failed to resume setup. Please try again.");
    }
  };

  const StripeSuccessMessage = ({ onClose }) => (
    <div className={classes.successMessage}>
      <div className={classes.successContent}>
        <svg
          className={classes.successIcon}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        <div className={classes.successText}>
          <h3>Setup Complete!</h3>
          <p>Your payment account has been successfully set up.</p>
        </div>
        <button onClick={onClose} className={classes.closeButton}>
          ×
        </button>
      </div>
    </div>
  );

  return (
    <div
      className={`${classes.page} ${
        isEditSingleStartupModalOpen ||
        isConnectionsModalOpen ||
        isIntroModalOpen ||
        isFollowersModalOpen ||
        isReactionsModalOpen ||
        isRepostsModalOpen ||
        isEditTeamModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isEditSingleStartupModalOpen
          ? { top: `-${editSingleStartupModalScrollPosition}px` }
          : isConnectionsModalOpen
          ? { top: `-${connectionsModalScrollPosition}px` }
          : isIntroModalOpen
          ? { top: `-${introModalScrollPosition}px` }
          : isFollowersModalOpen
          ? { top: `-${followersModalScrollPosition}px` }
          : isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isEditTeamModalOpen
          ? { top: `-${editTeamModalScrollPosition}px` }
          : {}
      }
    >
      {isMobileView ? (
        <MobileNavbar currentUser={currentUser} />
      ) : (
        <MainNavbar currentUser={currentUser} />
      )}
      {showStripeSuccess && (
        <StripeSuccessMessage onClose={() => setShowStripeSuccess(false)} />
      )}
      {showEditSingleStartupModal && (
        <EditSingleStartupModal
          startup={startupData}
          onClose={toggleEditSingleStartupModal}
          onUpdate={handleStartupUpdate}
          currentUser={currentUser}
          isFromStartupPage={true}
        />
      )}

      {showIntroModal && (
        <IntroModal
          onClose={toggleIntroModal}
          currentUser={currentUser}
          isStartup={true}
          startupData={startupData}
          startupOwnerId={startupData.ownerId}
          onProfileUpdate={(updatedData) => {
            setStartupData((prev) => ({
              ...prev,
              intro: updatedData.intro,
              introHtml: updatedData.introHtml,
            }));
            toggleIntroModal();
          }}
        />
      )}
      {showConnectionsModal && (
        <ConnectionsModal
          onClose={toggleConnectionsModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isTeamSelection={true}
          isEditTeam={false}
          startupData={startupData}
          teamMembers={teamMembers}
          onTeamMemberRemoved={() => {
            fetchTeamMembers();
          }}
          onInviteSent={() => setShowInvitePopup(true)}
        />
      )}
      {showEditTeamModal && (
        <ConnectionsModal
          onClose={toggleEditTeamModal}
          currentUserId={currentUser?.uid}
          loggedInUserId={currentUser?.uid}
          isOwnProfile={true}
          isTeamSelection={false}
          isEditTeam={true}
          startupData={startupData}
          teamMembers={teamMembers}
          onTeamMemberRemoved={() => {
            fetchTeamMembers();
          }}
        />
      )}
      <div className={classes.content}>
        <div className={classes.centreCards}>
          <div className={classes.profileCard}>
            <div className={classes.profileHeader}>
              <div
                className={classes.profileImage}
                onClick={isOwner ? toggleEditSingleStartupModal : undefined}
                style={{ cursor: isOwner ? "pointer" : "default" }}
              >
                <img
                  src={startupData.startupImage || startupImage}
                  alt="Startup"
                />
              </div>
              {isOwner && (
                <div onClick={toggleEditSingleStartupModal}>
                  <svg
                    className={classes.closeIcon}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                    />
                  </svg>
                </div>
              )}
            </div>

            <div className={classes.profileTitle}>
              {startupData.startupName}{" "}
              <span className={classes.connectionType}>• Startup</span>
            </div>

            <div>
              <div className={classes.bio}>{startupData.bio}</div>
              <div className={classes.location}>
                {startupData.city}, {startupData.country}
              </div>
              {startupData.link && (
                <div className={classes.link}>
                  <a
                    href={startupData.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {startupData.linkText || startupData.link}
                  </a>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="orangeRed"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                    />
                  </svg>
                </div>
              )}
              <div className={classes.data}>
                <span
                  className={classes.numbers}
                  onClick={handleShowFollowersModal}
                  style={{ cursor: "pointer" }}
                >
                  {startupData.followingCount?.toLocaleString() || 0} Follower
                  {startupData.followingCount === 1 ? "" : "s"}
                </span>

                {showFollowersModal && (
                  <FollowModal
                    onClose={handleCloseFollowersModal}
                    mode="followers"
                    startupData={{
                      id: startupData.id,
                      ownerId: startupData.ownerId,
                    }}
                    showDropdown={false}
                  />
                )}
              </div>
              {isOwner && (
                <div className={classes.buttons}>
                  {!startupData.bio && (
                    <div
                      className={classes.headlineButton}
                      onClick={toggleEditSingleStartupModal}
                    >
                      <svg
                        className=""
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a tagline
                    </div>
                  )}
                  {!startupData.link && (
                    <div
                      className={classes.button}
                      onClick={toggleEditSingleStartupModal}
                    >
                      <svg
                        className=""
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                      Add a link
                    </div>
                  )}
                </div>
              )}
              {!isOwner && (
                <div className={classes.buttons}>
                  <button
                    className={
                      followStatus === "Following"
                        ? classes.following
                        : classes.headlineButton
                    }
                    onClick={handleFollow}
                  >
                    {followStatus === "Following" ? (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 14a1 1 0 1 0-2 0v2h-2a1 1 0 1 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2v-2Z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M15.026 21.534A9.994 9.994 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2c2.51 0 4.802.924 6.558 2.45l-7.635 7.636L7.707 8.87a1 1 0 0 0-1.414 1.414l3.923 3.923a1 1 0 0 0 1.414 0l8.3-8.3A9.956 9.956 0 0 1 22 12a9.994 9.994 0 0 1-.466 3.026A2.49 2.49 0 0 0 20 14.5h-.5V14a2.5 2.5 0 0 0-5 0v.5H14a2.5 2.5 0 0 0 0 5h.5v.5c0 .578.196 1.11.526 1.534Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                    {followStatus}
                  </button>
                  <button className={classes.button}>
                    <svg
                      class="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7 6a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2h-2v-4a3 3 0 0 0-3-3H7V6Z"
                        clip-rule="evenodd"
                      />
                      <path
                        fill-rule="evenodd"
                        d="M2 11a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-7Zm7.5 1a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
                        clip-rule="evenodd"
                      />
                      <path d="M10.5 14.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
                    </svg>
                    Support
                  </button>
                </div>
              )}
            </div>
          </div>
          {renderDescriptionCard()}
          {renderActivityCard()}
        </div>

        <div className={classes.rightCards}>
          <div className={classes.card}>
            <div
              className={`${classes.cardTitle} ${
                !startupData.role ? classes.emptyCardTitle : ""
              }`}
            >
              Team
              <div className={classes.iconContainer}>
                {isOwner && (
                  <>
                    <div onClick={toggleConnectionsModal}>
                      <svg
                        className={classes.addIcon}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 12h14m-7 7V5"
                        />
                      </svg>
                    </div>
                    {teamMembers.length > 0 && (
                      <div onClick={toggleEditTeamModal}>
                        <svg
                          className={classes.experienceIcon}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="40"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="gray"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Owner Section */}
            <div className={classes.noStartupContainer}>
              <img
                src={startupData.ownerData?.profileImage || defaultImage}
                alt={
                  startupData.ownerData
                    ? `${startupData.ownerData.firstName} ${startupData.ownerData.lastName}`
                    : "Owner"
                }
                className={classes.userImage}
                onClick={() => navigate(`/${startupData.ownerData?.urlSlug}`)}
                style={{ cursor: "pointer" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
              />
              <div className={classes.startupAbout}>
                <div
                  className={classes.startupRole}
                  onClick={() => navigate(`/${startupData.ownerData?.urlSlug}`)}
                  style={{ cursor: "pointer" }}
                >
                  {startupData.ownerData?.firstName}{" "}
                  {startupData.ownerData?.lastName}
                </div>
                <div className={classes.startupName}>{startupData.role}</div>
                {(startupData.ownerData?.city ||
                  startupData.ownerData?.country) && (
                  <div className={classes.dates}>
                    {startupData.ownerData.city && startupData.ownerData.country
                      ? `${startupData.ownerData.city}, ${startupData.ownerData.country}`
                      : startupData.ownerData.city ||
                        startupData.ownerData.country}
                  </div>
                )}
              </div>
            </div>

            {/* Team Members Section */}
            {teamMembers.map((member) => (
              <div key={member.id} className={classes.startupContainer}>
                <img
                  src={member.userData.profileImage || defaultImage}
                  alt={`${member.userData.firstName} ${member.userData.lastName}`}
                  className={classes.userImage}
                  onClick={() => navigate(`/${member.userData.urlSlug}`)}
                  style={{ cursor: "pointer" }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = defaultImage;
                  }}
                />
                <div className={classes.startupAbout}>
                  <div
                    className={classes.startupRole}
                    onClick={() => navigate(`/${member.userData.urlSlug}`)}
                    style={{ cursor: "pointer" }}
                  >
                    {member.userData.firstName} {member.userData.lastName}
                  </div>
                  <div className={classes.startupName}>{member.role}</div>
                  {(member.userData.city || member.userData.country) && (
                    <div className={classes.dates}>
                      {member.userData.city && member.userData.country
                        ? `${member.userData.city}, ${member.userData.country}`
                        : member.userData.city || member.userData.country}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={classes.card}>
            <div className={classes.emptyCardTitle}>Donations</div>
            <div className={classes.defaultText}>
              {!stripeStatus?.detailsSubmitted
                ? "Set up payment so you can receive donations from members of the community who believe in your mission."
                : "Your payment account is set up and ready to receive donations."}
            </div>
            {isOwner && renderStripeStatus()}
            {/* Keep your existing donation examples/UI here */}
            <div className={classes.startupContainer}>
              <img
                src={defaultImage}
                alt={""}
                className={classes.noUserImage}
              />
              <div className={classes.startupAbout}>
                <div className={classes.noStartupRole}>Joe Bloggs</div>
                <div className={classes.noStartupName}>£500</div>
                <div className={classes.noDescription}>Message</div>
              </div>
            </div>
          </div>

          <TermsAndConditions />
        </div>

        {showReactionsModal && activePostReactions && (
          <ReactionsModal
            onClose={() => {
              setShowReactionsModal(false);
              setIsReactionsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, reactionsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReactions.postId}
            reactions={activePostReactions.reactions}
            currentUserId={currentUser?.uid}
          />
        )}

        {showRepostsModal && activePostReposts && (
          <RepostsModal
            onClose={() => {
              setShowRepostsModal(false);
              setIsRepostsModalOpen(false);
              setTimeout(() => {
                window.scrollTo(0, repostsModalScrollPosition);
              }, 0);
            }}
            postId={activePostReposts.postId}
            reposts={activePostReposts.reposts}
            currentUserId={currentUser?.uid}
          />
        )}
      </div>
      {showInvitePopup && (
        <InvitePopup
          onClose={() => setShowInvitePopup(false)}
          isModalOpen={showConnectionsModal}
        />
      )}
    </div>
  );
}

export default Startup;
